<template>
  <teleport to="#modals" v-if="show">
    <transition
      enter-active-class="transition-all duration-300 ease-out"
      enter-class="scale-75 opacity-0"
      enter-to-class="scale-100 opacity-100"
      leave-active-class="transition-all duration-200 ease-in"
      leave-class="scale-100 opacity-100"
      leave-to-class="opacity-0 scale-70"
    >
      <div
        class="fixed inset-x-0 bottom-0 z-50 flex items-center sm:pb-4 sm:inset-0 sm:px-4 sm:justify-center"
        v-if="show"
      >
        <div
          @click="dismiss"
          class="fixed inset-0 transition-opacity"
          style="background-color: rgba(228, 228, 231, 0.5)"
        ></div>
        <div
          class="w-full transform bg-white shadow-xl sm:rounded-lg"
          :class="`max-w-${size}`"
        >
          <slot></slot>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  props: {
    show: { required: true },
    preventBackgroundScrolling: { default: true },
    size: {
      type: String,
      default: "md",
    },
  },
  data() {
    return {
      escapeHandler: (e) => {
        if (e.key === "Escape" && this.show) {
          this.dismiss();
        }
      },
    };
  },
  methods: {
    dismiss() {
      this.emitter.emit("close");
    },
  },
  beforeUnmount() {
    document.body.style.removeProperty("overflow");

    document.removeEventListener("keydown", this.escapeHandler);
  },
  watch: {
    show: {
      immediate: true,
      handler: function (show) {
        const mainElement = document.getElementById("main");

        if (show) {
          this.preventBackgroundScrolling &&
            document.body.style.setProperty("overflow", "hidden");

          if (mainElement) {
            mainElement.style.filter = "blur(2px)";
            mainElement.style["webkitFilter"] = "blur(2px)";
          }
        } else {
          this.preventBackgroundScrolling &&
            document.body.style.removeProperty("overflow");

          if (mainElement) {
            mainElement.style.filter = null;
            mainElement.style["webkitFilter"] = null;
          }
        }
      },
    },
  },
  created() {
    document.addEventListener("keydown", this.escapeHandler);
  },
};
</script>