<template>
  <on-click-outside :action="close">
    <button
      type="button"
      ref="element"
      class="focus:outline-none"
      v-bind="$attrs"
      @click="toggleVisibility"
    >
      <slot></slot>
      <div
        ref="dropdown"
        :style="computedPositioningStyle"
        @click.stop="show = autoClose ? false : true"
        v-if="show"
      >
        <slot name="dropdown"></slot>
      </div>
    </button>
  </on-click-outside>
</template>

<script>
import OnClickOutside from "./OnClickOutside";
import { createPopper } from "@popperjs/core";

export default {
  props: {
    placement: {
      type: String,
      default: "bottom-end",
    },
    boundary: {
      type: String,
      default: "scrollParent",
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
    popup: {
      type: Boolean,
      default: true,
    },
    positioningStyle: {
      type: Object,
    },
  },
  components: {
    OnClickOutside,
  },
  data() {
    return {
      show: false,
    };
  },
  beforeUnmount() {
    if (this.popper !== undefined) {
      this.popper.destroy();
    }

    document.removeEventListener("keydown", this.escapeHandler);
  },
  created() {
    this.escapeHandler = (e) => {
      if (e.key === "Escape" && this.show) {
        this.close();
      }
    };

    document.addEventListener("keydown", this.escapeHandler);
  },
  watch: {
    show: {
      immediate: true,
      handler: function (show) {
        if (this.popup) {
          if (show) {
            this.$nextTick(() => {
              this.popper = createPopper(
                this.$refs.element,
                this.$refs.dropdown,
                {
                  placement: this.placement,
                }
              );
            });
          }
        }
      },
    },
  },
  computed: {
    computedPositioningStyle() {
      const styles = {
        zIndex: 99999,
        ...this.positioningStyle,
      };

      if (this.popup) {
        styles.position = "absolute";
      }

      return styles;
    },
  },
  methods: {
    toggleVisibility() {
      this.show = !this.show;
    },
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
  },
};
</script>
