<template>
  <teleport to="#modals" v-if="show">
    <div class="fixed inset-0 overflow-hidden">
      <div class="absolute inset-0 overflow-hidden">
        <transition
          enter-active-class="duration-1000 ease-out"
          enter-class="opacity-0"
          enter-to-class="scale-100 opacity-100"
          leave-active-class="duration-1000 ease-in-out"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
        >
        </transition>
        <section class="absolute inset-y-0 right-0 flex max-w-full pl-10">
          <transition
            enter-active-class="transition duration-1000 ease-in-out transform"
            enter-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-active-class="transition duration-1000 ease-in-out transform"
            leave-class="translate-x-0"
            leave-to-class="translate-x-full"
          >
            <div class="relative w-screen max-w-lg" v-if="show">
              <div
                class="flex flex-col h-full py-6 space-y-6 overflow-y-scroll bg-white shadow-xl"
              >
                <div class="relative flex-1">
                  <slot></slot>
                </div>
              </div>
            </div>
          </transition>
        </section>
      </div>
    </div>
  </teleport>
</template>

<script>
export default {
  props: {
    show: { required: true },
    preventBackgroundScrolling: { default: true },
  },
  data() {
    return {
      escapeHandler: (e) => {
        if (e.key === "Escape" && this.show) {
          this.dismiss();
        }
      },
    };
  },
  methods: {
    dismiss() {
      this.emitter.emit("close-slideover");
    },
  },
  unmounted() {
    document.removeEventListener("keydown", this.escapeHandler);

    document.body.style.removeProperty("overflow");
  },
  watch: {
    show: {
      immediate: true,
      handler: function (show) {
        if (show) {
          this.preventBackgroundScrolling &&
            document.body.style.setProperty("overflow", "hidden");
        } else {
          this.preventBackgroundScrolling &&
            document.body.style.removeProperty("overflow");
        }
      },
    },
  },
  created() {
    document.addEventListener("keydown", this.escapeHandler);
  },
};
</script>